import {
  json,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import { Form, useLoaderData, type MetaFunction } from "@remix-run/react";
import { SUCCESS_REDIRECT } from "~/common/utils/constants";
import { formatTitle } from "~/common/utils/formatTitle";
import { authenticator } from "~/services/auth.server";
import { sessionStorage } from "~/services/session.server";

export const meta: MetaFunction = () => {
  return [{ title: formatTitle("Login") }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: SUCCESS_REDIRECT,
  });

  const session = await sessionStorage.getSession(
    request.headers.get("Cookie")
  );

  return json({
    magicLinkSent: session.has("auth:magiclink"),
    magicLinkEmail: session.get("auth:email"),
  });
}

export const action = async ({ request }: ActionFunctionArgs) => {
  // The success redirect is required in this action, this is where the user is
  // going to be redirected after the magic link is sent, note that here the
  // user is not yet authenticated, so you can't send it to a private page.
  await authenticator.authenticate("email-link", request, {
    successRedirect: "/login",
    // If this is not set, any error will be throw and the ErrorBoundary will be
    // rendered.
    failureRedirect: "/login",
  });
};

export default function Login() {
  const { magicLinkSent, magicLinkEmail } = useLoaderData<typeof loader>();

  return magicLinkSent ? (
    <div className="alert alert-primary" role="alert">
      Check Your Email ({magicLinkEmail})
    </div>
  ) : (
    <article className="card card-outline mb-4">
      <div className="card-body">
        <header>
          <h4 className="card-title">Log In</h4>
        </header>
        <Form action="/login" method="post">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Form>
      </div>
    </article>
  );
}
